<script type="text/babel">
export default {
  data() {
    return {
      active: false,
    };
  },
  computed: {
    /**
     * @returns {Array}
     * @example [{
     *    text: 'Julies Ceaser',
     *    color: '',
     *    fontSize: '12px',
     *    seperator: 'dashed',
     *    bold: false,
     *    tooltip: false
     * }]
     */
    contents() {
      return (this.params.fields ?? []).reduce((field_set, field = {}) => {
        if (!field.column) return field_set;

        let text = this.getFormattedValue(
          field.column,
          field.formatter ?? undefined
        );
        let customClass = this.getCustomClass(
          field.column,
          field.customClass ?? undefined
        )
        if (text)
          field_set.push({
            text,
            customClass,
            column: field.column,
            color: this.extractColor({
              color: field.color,
              context: this.params.context,
            }),
            fontSize: field.font_size ?? "12px",
            seperator: field.seperator ?? "",
            bold: field.bold ?? false,
            strong: field.strong ?? false,
            tooltip: field.tooltip ?? false,
            component: field.component ?? {},
          });
        return field_set;
      }, []);
    },
    columnDefs() {
      return this.params.api.getColumnDefs();
    },
    baseParams() {
      return {
        node: this.params.node,
        data: this.params.data,
        api: this.params.api,
        columnApi: this.params.columnApi,
        context: this.params.$caller,
      };
    },
  },
  methods: {
    extractColor({ color, context }) {
      if (typeof color !== "undefined") {
        return typeof color === "string"
          ? color
          : color.call(context, this.params.data);
      } else return "#000000";
    },
    getFormattedValue(column, customCallback = (val) => val) {
      let value = this.params.data?.[column] ?? "";
      let col = this.params.columnApi.getColumn(column);
      let colDef = col?.colDef ?? {};
      let valueGetter = colDef.valueGetter;
      let valueFormatter = colDef.valueFormatter;

      if (valueGetter) {
        value = this.evaluateAndReturn(
          valueGetter,
          this.getValueGetterParams(column)
        );
      }
      if (valueFormatter) {
        value = this.evaluateAndReturn(
          valueFormatter,
          this.getValueFormatterParams(column, value)
        );
      }

      return customCallback(value);
    },
    evaluateAndReturn(callBack, params) {
      if (typeof callBack === "function") {
        return callBack(params);
      } else return callBack;
    },
    getValueGetterParams(column) {
      let currentIndex = this.params.rowIndex;
      let row = this.params.api.getDisplayedRowAtIndex(currentIndex);
      let AGcolumn = this.params.columnApi.getColumn(column);

      return {
        getValue: (field) => this.params.getValue.bind(null, field, row)(),
        ...this.baseParams,
        column: AGcolumn,
        colDef: AGcolumn.getColDef(),
      };
    },
    getValueFormatterParams(column, value) {
      let AGcolumn = this.params.columnApi.getColumn(column);

      return {
        value,
        ...this.baseParams,
        column: AGcolumn,
        colDef: AGcolumn.getColDef(),
      };
    },
    getComponentProps(props) {
      return {
        ...props,
        data: this.params.data,
      };
    },
    getCustomClass(column, callBack = () => "" ) {
      let value = this.params.data?.[column] ?? "";
      return callBack(value)
    }
  },
};
</script>

<template>
  <div :class="{ 'multi-line': contents.length > 1 }" class="multi-line-group">
    <template v-for="(field, index) in contents">
      <hb-tooltip
        :key="index"
        :disabled="!field.tooltip"
        class="multiline-tooltip"
      >
        <template v-slot:item>
        <!-- render component when component details are passed through cellParams  -->
          <component
            v-if="field.component && field.component.component"
            :is="field.component.component"
            v-bind="getComponentProps(field.component.props)"
            :field-data="field"
          />

          <span
            v-else
            :class="[
              field.seperator,
              field.customClass,
              { bold: field.bold },
              { 'font-weight-medium': field.strong },
            ]"
            :style="{ fontSize: field.fontSize, color: field.color }"
            v-html="field.text"
          >
          </span>
        </template>
        <template v-slot:body>
          {{ field.text }}
        </template>
      </hb-tooltip>
    </template>
  </div>
</template>

<style lang="scss">
.multi-line-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    &.dashed {
      border-top: 1px dashed #637381;
    }
    &.dotted {
      border-top: 1px dotted #637381;
    }
    &.solid {
      border-top: 1px solid #637381;
    }
    //Added to bring bottom underline when only one token exist
    &.dashed-underline {
      padding-bottom: 1px;
      border-bottom: 1px dashed #637381;
    }
    &.dotted-underline {
      padding-bottom: 1px;
      border-bottom: 1px dotted #637381;
    }
    &.solid-underline {
      padding-bottom: 1px;
      border-bottom: 1px solid #637381;
    }
  }
}
.multi-line {
  margin-top: -5px;
  :last-child {
    margin-top: -4px;
  }
}
</style>
