<script>
import ReportViewerV2 from "../generics/AG/ReportViewerV2.vue";
import MultiLineRenderer from "./MultiLineRenderer.vue"
import Menu from "./Menu.vue";
import BITooltip from "./BITooltip.vue";

import HeaderFilter from "../../components/includes/ReportFunctions/HeaderFilter.vue";

export default {
  components: {
    ReportViewerV2,
    HeaderFilter,
	MultiLineRenderer
  },
  data() {
    return {
      api: null,
    };
  },
  computed: {
    options() {
      return {
        style: [{ width: "100%", height: "100%" }],
        class: ["ag-theme-material"],
        listeners: {
          onKebabClick: this.injectPopup,
          onGridReady: (params) => {
            this.api = params.api;
          },
        },
        props: {
          defaultColDef: {
            headerComponent: "HeaderFilter",
		        autoHeight: true,
            resizable: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,

          },
          columnDefs: [
            {
              field: "name",
              cellRenderer: 'MultiLineRenderer',
              cellRendererParams: {
                fields: [
                  {
                    column: 'name',
                    seperator: 'dashed'
                  },
                  {
                    column: 'mobile',
                    color: 'red',
                    font_size: '12px'
                  }
                ],
                context: this,
              }
            },
            { field: "proficiency", headerName: "Proficiant in anything but nothing" },
            { field: "country" },
            { field: "mobile" },
            { field: "landline" },
            { field: "destination" },
            { field: "dob" },
            { field: "language" },
          ],
          rowData: [
            {
              name: "Fannie",
              proficiency: "Belarusian",
              country: "Poland",
              mobile: "42-126-9703",
              landline: "94-061-2160",
              destination: "PL",
              dob: "1/31/2022",
              language: "Northern Sotho",
              designation: "Electrical Engineer",
            },
            {
              name: "Shea",
              proficiency: "Hiri Motu",
              country: "Brazil",
              mobile: "31-725-9267",
              landline: "99-852-1138",
              destination: "BR",
              dob: "7/12/2021",
              language: "Kannada",
              designation: "Assistant Professor",
            },
            {
              name: "Donni",
              proficiency: "Kannada",
              country: "Finland",
              mobile: "53-456-4289",
              landline: "30-748-7753",
              destination: "FI",
              dob: "3/5/2022",
              language: "Mongolian",
              designation: "Biostatistician I",
            },
            {
              name: "Fanechka",
              proficiency: "Portuguese",
              country: "Honduras",
              mobile: "91-644-1536",
              landline: "77-805-5810",
              destination: "HN",
              dob: "4/13/2022",
              language: "French",
              designation: "Help Desk Operator",
            },
            {
              name: "Madlen",
              proficiency: "Italian",
              country: "Portugal",
              mobile: "64-230-1614",
              landline: "78-870-8391",
              destination: "PT",
              dob: "11/16/2021",
              language: "Chinese",
              designation: "Help Desk Operator",
            },
            {
              name: "Bob",
              proficiency: "Quechua",
              country: "Indonesia",
              mobile: "90-544-1268",
              landline: "81-380-2782",
              destination: "ID",
              dob: "7/5/2021",
              language: "Pashto",
              designation: "Software Consultant",
            },
            {
              name: "Bertine",
              proficiency: "Sotho",
              country: "Greece",
              mobile: "96-329-3979",
              landline: "00-974-2706",
              destination: "GR",
              dob: "9/17/2021",
              language: "West Frisian",
              designation: "Web Developer III",
            },
            {
              name: "Camala",
              proficiency: "Portuguese",
              country: "China",
              mobile: "00-209-8019",
              landline: "01-868-3596",
              destination: "CN",
              dob: "4/29/2022",
              language: "Italian",
              designation: "Structural Analysis Engineer",
            },
            {
              name: "Georg",
              proficiency: "Tok Pisin",
              country: "Cyprus",
              mobile: "34-896-2979",
              landline: "90-257-9746",
              destination: "CY",
              dob: "4/16/2022",
              language: "Oriya",
              designation: "Internal Auditor",
            },
            {
              name: "Winthrop",
              proficiency: "Aymara",
              country: "Japan",
              mobile: "15-162-7566",
              landline: "01-646-6950",
              destination: "JP",
              dob: "11/14/2021",
              language: "Papiamento",
              designation: "Accountant IV",
            },
            {
              name: "Ethelind",
              proficiency: "Spanish",
              country: "China",
              mobile: "36-416-7333",
              landline: "89-619-3119",
              destination: "CN",
              dob: "5/9/2022",
              language: "Assamese",
              designation: "Senior Quality Engineer",
            },
            {
              name: "Anne",
              proficiency: "Malayalam",
              country: "Thailand",
              mobile: "54-141-6823",
              landline: "20-941-0079",
              destination: "TH",
              dob: "8/5/2021",
              language: "Hindi",
              designation: "Systems Administrator III",
            },
            {
              name: "Trixi",
              proficiency: "Catalan",
              country: "Namibia",
              mobile: "98-194-7141",
              landline: "49-147-2009",
              destination: "NA",
              dob: "3/30/2022",
              language: "Norwegian",
              designation: "Statistician II",
            },
            {
              name: "Angeline",
              proficiency: "Icelandic",
              country: "Somalia",
              mobile: "46-909-0359",
              landline: "15-025-8346",
              destination: "SO",
              dob: "8/28/2021",
              language: "Khmer",
              designation: "Staff Scientist",
            },
            {
              name: "Brooks",
              proficiency: "Māori",
              country: "Netherlands",
              mobile: "75-632-3746",
              landline: "18-548-8588",
              destination: "NL",
              dob: "7/15/2021",
              language: "Māori",
              designation: "Software Engineer I",
            },
            {
              name: "Lezlie",
              proficiency: "Burmese",
              country: "Sweden",
              mobile: "14-134-7730",
              landline: "57-717-1673",
              destination: "SE",
              dob: "5/1/2022",
              language: "Czech",
              designation: "Health Coach III",
            },
            {
              name: "Burton",
              proficiency: "Haitian Creole",
              country: "France",
              mobile: "82-441-5827",
              landline: "81-438-2321",
              destination: "FR",
              dob: "5/14/2022",
              language: "Georgian",
              designation: "Help Desk Operator",
            },
            {
              name: "Jania",
              proficiency: "Macedonian",
              country: "Poland",
              mobile: "07-612-9640",
              landline: "18-858-8897",
              destination: "PL",
              dob: "5/13/2022",
              language: "Greek",
              designation: "Quality Control Specialist",
            },
            {
              name: "Arnie",
              proficiency: "Papiamento",
              country: "Tanzania",
              mobile: "92-439-0620",
              landline: "01-826-5604",
              destination: "TZ",
              dob: "8/24/2021",
              language: "Tajik",
              designation: "Biostatistician II",
            },
            {
              name: "Hilary",
              proficiency: "Tswana",
              country: "Poland",
              mobile: "66-102-8013",
              landline: "39-610-8451",
              destination: "PL",
              dob: "3/11/2022",
              language: "Latvian",
              designation: "Assistant Professor",
            },
            {
              name: "Moe",
              proficiency: "Guaraní",
              country: "Ukraine",
              mobile: "56-231-5091",
              landline: "91-882-3696",
              destination: "UA",
              dob: "10/28/2021",
              language: "Quechua",
              designation: "Actuary",
            },
            {
              name: "Clarke",
              proficiency: "Moldovan",
              country: "China",
              mobile: "42-152-3461",
              landline: "61-919-6314",
              destination: "CN",
              dob: "1/29/2022",
              language: "Azeri",
              designation: "Environmental Tech",
            },
            {
              name: "Juliet",
              proficiency: "Fijian",
              country: "Colombia",
              mobile: "51-098-6753",
              landline: "30-085-4044",
              destination: "CO",
              dob: "5/14/2022",
              language: "Filipino",
              designation: "Assistant Manager",
            },
            {
              name: "Tobye",
              proficiency: "Irish Gaelic",
              country: "Indonesia",
              mobile: "21-462-9290",
              landline: "59-991-0288",
              destination: "ID",
              dob: "10/23/2021",
              language: "Kurdish",
              designation: "Financial Analyst",
            },
            {
              name: "Candis",
              proficiency: "Tetum",
              country: "Czech Republic",
              mobile: "22-355-4972",
              landline: "60-733-3204",
              destination: "CZ",
              dob: "12/25/2021",
              language: "Papiamento",
              designation: "Research Associate",
            },
            {
              name: "Tomasina",
              proficiency: "Irish Gaelic",
              country: "Lithuania",
              mobile: "90-901-1158",
              landline: "00-855-8281",
              destination: "LT",
              dob: "2/11/2022",
              language: "Greek",
              designation: "Automation Specialist I",
            },
            {
              name: "Joachim",
              proficiency: "Malayalam",
              country: "China",
              mobile: "07-722-1543",
              landline: "27-989-0804",
              destination: "CN",
              dob: "9/23/2021",
              language: "Dari",
              designation: "Senior Editor",
            },
            {
              name: "Drud",
              proficiency: "Chinese",
              country: "Syria",
              mobile: "36-392-6693",
              landline: "67-284-1220",
              destination: "SY",
              dob: "1/26/2022",
              language: "Latvian",
              designation: "Senior Financial Analyst",
            },
            {
              name: "Daniella",
              proficiency: "Irish Gaelic",
              country: "Russia",
              mobile: "67-829-4156",
              landline: "38-695-3814",
              destination: "RU",
              dob: "11/7/2021",
              language: "Portuguese",
              designation: "VP Quality Control",
            },
            {
              name: "Carline",
              proficiency: "Haitian Creole",
              country: "Thailand",
              mobile: "18-074-7338",
              landline: "62-827-3295",
              destination: "TH",
              dob: "5/13/2022",
              language: "Thai",
              designation: "Pharmacist",
            },
            {
              name: "Freddie",
              proficiency: "Chinese",
              country: "China",
              mobile: "69-733-7241",
              landline: "29-864-7413",
              destination: "CN",
              dob: "5/20/2022",
              language: "Bislama",
              designation: "Chemical Engineer",
            },
            {
              name: "Zsa zsa",
              proficiency: "Kashmiri",
              country: "Mexico",
              mobile: "46-372-9306",
              landline: "85-561-0678",
              destination: "MX",
              dob: "3/21/2022",
              language: "Icelandic",
              designation: "Administrative Assistant I",
            },
            {
              name: "Tara",
              proficiency: "Macedonian",
              country: "China",
              mobile: "65-244-6917",
              landline: "11-087-0249",
              destination: "CN",
              dob: "11/18/2021",
              language: "Maltese",
              designation: "Senior Developer",
            },
            {
              name: "Arvin",
              proficiency: "Swahili",
              country: "Russia",
              mobile: "93-476-5546",
              landline: "18-505-8184",
              destination: "RU",
              dob: "7/7/2021",
              language: "Somali",
              designation: "Pharmacist",
            },
            {
              name: "Kacy",
              proficiency: "Luxembourgish",
              country: "France",
              mobile: "70-741-8055",
              landline: "86-417-6701",
              destination: "FR",
              dob: "12/5/2021",
              language: "Aymara",
              designation: "VP Product Management",
            },
            {
              name: "Ailbert",
              proficiency: "Pashto",
              country: "Peru",
              mobile: "74-246-7683",
              landline: "68-558-7900",
              destination: "PE",
              dob: "10/3/2021",
              language: "Arabic",
              designation: "Senior Editor",
            },
            {
              name: "Merlina",
              proficiency: "Montenegrin",
              country: "Thailand",
              mobile: "11-207-6677",
              landline: "07-672-8518",
              destination: "TH",
              dob: "8/18/2021",
              language: "Swedish",
              designation: "Desktop Support Technician",
            },
            {
              name: "Carmella",
              proficiency: "Nepali",
              country: "Philippines",
              mobile: "09-598-6722",
              landline: "02-550-5477",
              destination: "PH",
              dob: "11/4/2021",
              language: "Portuguese",
              designation: "Help Desk Technician",
            },
            {
              name: "Kaylee",
              proficiency: "Romanian",
              country: "Russia",
              mobile: "76-308-4888",
              landline: "37-714-0301",
              destination: "RU",
              dob: "3/14/2022",
              language: "Amharic",
              designation: "Social Worker",
            },
            {
              name: "Kele",
              proficiency: "Nepali",
              country: "China",
              mobile: "88-498-3145",
              landline: "68-644-6972",
              destination: "CN",
              dob: "5/19/2022",
              language: "Korean",
              designation: "Environmental Tech",
            },
            {
              name: "Angele",
              proficiency: "Icelandic",
              country: "Philippines",
              mobile: "07-116-4222",
              landline: "04-672-3623",
              destination: "PH",
              dob: "4/21/2022",
              language: "Assamese",
              designation: "Help Desk Operator",
            },
            {
              name: "Lee",
              proficiency: "New Zealand Sign Language",
              country: "South Korea",
              mobile: "38-313-9594",
              landline: "19-256-8039",
              destination: "KR",
              dob: "3/22/2022",
              language: "Lithuanian",
              designation: "Quality Engineer",
            },
            {
              name: "Walsh",
              proficiency: "Georgian",
              country: "Indonesia",
              mobile: "13-350-8653",
              landline: "43-462-9730",
              destination: "ID",
              dob: "1/20/2022",
              language: "Arabic",
              designation: "Recruiting Manager",
            },
            {
              name: "Cathy",
              proficiency: "Tetum",
              country: "Cyprus",
              mobile: "62-010-5779",
              landline: "81-566-7504",
              destination: "CY",
              dob: "6/5/2022",
              language: "Lithuanian",
              designation: "Librarian",
            },
            {
              name: "Tris",
              proficiency: "Telugu",
              country: "Brazil",
              mobile: "19-269-4965",
              landline: "32-449-3653",
              destination: "BR",
              dob: "4/28/2022",
              language: "Tsonga",
              designation: "Help Desk Technician",
            },
            {
              name: "Lyman",
              proficiency: "Greek",
              country: "China",
              mobile: "71-829-0540",
              landline: "55-758-3626",
              destination: "CN",
              dob: "11/19/2021",
              language: "Finnish",
              designation: "Executive Secretary",
            },
            {
              name: "Bondy",
              proficiency: "Gagauz",
              country: "China",
              mobile: "78-768-8888",
              landline: "71-809-9205",
              destination: "CN",
              dob: "12/8/2021",
              language: "Punjabi",
              designation: "Marketing Manager",
            },
            {
              name: "Brod",
              proficiency: "Swati",
              country: "China",
              mobile: "26-466-1560",
              landline: "61-303-4053",
              destination: "CN",
              dob: "3/20/2022",
              language: "Finnish",
              designation: "Help Desk Operator",
            },
            {
              name: "Horatio",
              proficiency: "Dari",
              country: "Philippines",
              mobile: "93-792-2948",
              landline: "23-359-6289",
              destination: "PH",
              dob: "5/5/2022",
              language: "Dzongkha",
              designation: "Information Systems Manager",
            },
            {
              name: "Tatum",
              proficiency: "Somali",
              country: "Russia",
              mobile: "63-996-1844",
              landline: "94-992-3937",
              destination: "RU",
              dob: "7/13/2021",
              language: "Pashto",
              designation: "VP Product Management",
            },
          ],
          BIHelpers: {
            kebab: {
              enabled: true,
              icon: "mdi-dots-vertical",
              component: {
                definition: Menu,
                props: {
                  items: [
                    {
                      text: "Inbox dude inbox",
                    },
                    {
                      text: "Star everything down",
                    },
                    {
                      text: "Send to many",
                    },
                    {
                      text: "Draft this",
                    },
                  ],
                },
              },
              action: "click",
            },
            info: {
              enabled: true,
              icon: "mdi-information",
              component: {
                definition: BITooltip,
              },
              action: "hover",
            },
          },
        },
      };
    },
  },
  methods: {
    injectPopup(event) {
      console.log(event);
    },
  },
};
</script>

<template>
  <div
    class="section-content pt-4"
    :class="{
      'mt-10': $vuetify.breakpoint.xs,
      'mt-n3': $vuetify.breakpoint.sm,
    }"
  >
    <hb-header full>
      <hb-page-header title="BI Reports"> </hb-page-header>
    </hb-header>
    <div style="flex: 1">
      <ReportViewerV2 :options="options" />
    </div>
  </div>
</template>
